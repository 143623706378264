import Vue from 'vue'
import VueRouter from 'vue-router'

import WerbungListe from "../views/WerbungListe"
import Werbung from "../views/Werbung"


Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "werbung.liste",
    component: WerbungListe
  },
  {
    path: "/werbung",
    name: "werbung.neu",
    component: Werbung
  },
  {
    path: "/werbung/:id",
    name: "werbung.id",
    component: Werbung,
    props: true
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

export default router
