import api from "../config"

import position from "./position"
import screen from "./screen"

export default {
  get: (page, count) => {
    return api.get("werbung/", { params: { page, count}})
  },
  getById: (id) => {
    return api.get("werbung/", { params: { id }})
  },
  post: (werbung) => {
    return api.post("werbung/", werbung)
  },
  put: (id, werbung) => {
    return api.put("werbung/", werbung, { params: { id }})
  },
  delete: (id) => {
    return api.delete("werbung/", { params: { id }})
  },
  position,
  screen
}
