export default [
  {
    value: 1.778,
    text: "16:9",
    example: "1366x768"
  },
  {
    value: 1.33,
    text: "4:3",
    example: "1024x768"
  },
  {
    value: 1.6,
    text: "16:10",
    example: "1280x800"
  }
]
