<template>
  <b-list-group-item>
    <b-row v-if="edit">
      <b-col cols="6">
        <b-form-group label="Seitenverhältnis">
        <v-select
          v-model.number="seitenverhaeltnis"
          :reduce="v => v.value"
          :options="seitenverhaeltnisse"
          :show.sync="popoverShow"
          :clearable="false"
          label="value"
          >
          <template #option="o">
            <span>{{ o.text }} (z. B. {{ o.example }})</span>
          </template>
          <template #selected-option="o">
            <span>{{ o.text }}</span>
          </template>
        </v-select>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <dropzone ref="dropzone" :id="dropzoneId" :options="dropzoneOptions" @vdropzone-success="uploadSuccess"/>
      </b-col>
      <b-col cols="2">
        <button class="btn btn-sm btn-success w-100" @click="speichern">
          Speichern
        </button>
        <button class="btn btn-sm btn-secondary w-100 mt-2" @click="cancel">
          Abbrechen
        </button>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="9">
        <div v-if="fileUrl && istVideo">
          <video height="300px" :src="fileUrl" controls></video>
        </div>
        <div v-if="fileUrl && !istVideo">
          <img height="300px" :src="fileUrl"/>
        </div>
      </b-col>
      <b-col cols="1">
        <label>Seitenverhältnis</label>
        <span v-if="seitenverhaeltnisObj">{{ seitenverhaeltnisObj.text }}</span>
      </b-col>
      <b-col cols="2" class="text-right" id="delete-container">
        <b-button-group size="sm">
          <button class="btn btn-success" @click="edit = true">
            <i class="fa fa-pencil" />
          </button>
          <button class="btn btn-danger" :id="'delete-popover-open-'+popoverId"><i class="fa fa-trash" /></button>
          <b-popover
            :target="'delete-popover-open-'+popoverId"
            triggers="focus"
            placement="auto"
            ref="deletePopover"
            title="Werbescreen löschen"
          >
            <div>Soll der Werbescreen wirklick gelöscht werden?</div>
            <button class="btn btn-sm btn-danger w-100 mt-3" @click="deleteScreen()">Löschen</button>
          </b-popover>
        </b-button-group>
      </b-col>
    </b-row>
  </b-list-group-item>
</template>

<script>
  import api from "../api";

  import dropzone from "vue2-dropzone";
  import seitenverhaeltnisse from "../static/seitenverhaeltnisse";
  import { v4 as uuid } from "uuid";
  import server from "../server"

  export default {
    name: "Werbescreen",
    components: {
      dropzone,
    },
    props: {
      werbung: {
        type: String,
        required: true,
      },
      screen: {
        type: Object,
        default: null,
      },
    },
    watch: {},
    data() {
      return {
        edit: false,
        id: null,
        seitenverhaeltnis: null,
        hash: null,
        istVideo: false,
        url: null,
        mimeType: false,
        originalName: null,
        fileId: null,
        fileName: null,
        popoverShow: false
      };
    },
    created() {
      if (!this.screen) this.edit = true;
      else {
        this.initializeScreen(this.screen)
      }
    },
    computed: {
      dropzoneOptions() {
        let url = `${server.url}werbung/screen/file/?screen=${this.screen?.id}`;

        return {
          url: url,
          //thumbnailWidth: 150,
          //thumbnailHeight: 150,
          autoProcessQueue: false,
          acceptedFiles: 'image/*,video/*',
          uploadMultiple: false,
          addRemoveLinks: true,
          dictDefaultMessage: "Bild oder Video auswählen",
          dictRemoveFile: "<i class='fa fa-times'/>",
          dictInvalidFileType: "Dieser Dateityp wird nicht unterstützt",
          dictResponseError: "Beim Hochladen der Datei ist etwas schief gelaufen"
          //withCredentials: true,
        };
      },
      dropzoneId() {
        return uuid();
      },
      popoverId() {
        return uuid();
      },
      seitenverhaeltnisse() {
        return seitenverhaeltnisse;
      },
      seitenverhaeltnisObj() {
        if(this.seitenverhaeltnis) {
          let obj = this.seitenverhaeltnisse.find(s => s.value === this.seitenverhaeltnis)
          return obj || null;
        } else {
          return null;
        }
      },
      fileUrl() {
        if(this.url && this.istVideo) {
          return `${server.url}${this.url}`
        } else if(this.fileName && !this.istVideo) {
          return `${server.url}/pic/${this.fileName}`
        }
          return null;
        }
    },
    methods: {
      async speichern() {
        let json = {
          id: this.id,
          seitenverhaeltnis: parseFloat(this.seitenverhaeltnis)
        };

        let files = this.$refs.dropzone.getQueuedFiles();


        if (this.id) {
          let response = await api.werbung.screen.put(this.id, json);
          //this.$emit("updated", response.data);
          //this.edit = false;
          this.initializeScreen(response.data)
        } else {
          let response = await api.werbung.screen.post(this.werbung, json);
          this.initializeScreen(response.data);
          //this.$emit("updated", response.data);
        }

        if(files.length > 0) {
          this.processDropzone();
        } else {
          this.edit = false;
        }
      },
      initializeScreen(screen) {
        this.id = screen.id;
        this.seitenverhaeltnis = screen.seitenverhaeltnis;
        this.istVideo = screen.istVideo;
        this.mimeType = screen.mimeType;
        this.url = screen.url;
        this.fileId = screen.fileId;
        this.fileName = screen.fileName
      },
      cancel() {
        if (this.screen) this.edit = false;
        else this.$emit("cancel");
      },
      async deleteScreen() {
        if (this.id) {
          await api.werbung.screen.delete(this.id);
          //this.$refs.deletePopover.close();
          this.$emit("deleted");
        }
      },
      processDropzone() {
        this.updateDropzoneUrl();
        this.$refs.dropzone.processQueue();
      },
      updateDropzoneUrl() {
        let url = `${process.env.VUE_APP_API_URL}werbung/screen/file/?screen=${this.id}`;
        this.$refs.dropzone.setOption('url', url);
      },
      async uploadSuccess() {
        if(this.id) {
          let response = await api.werbung.screen.getById(this.id);
          this.initializeScreen(response.data)
          this.edit = false;
        }
      },
      closeDeletePopover() {
        this.popoverShow = false;
      }
    },
  };
</script>

<style lang="scss">
  .dz-remove {
    bottom: inherit;
    top: 15px;
    right: 15px;
  }
</style>

<style scoped lang="scss">

label {
  font-weight: 700;
}
</style>
