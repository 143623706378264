<template>
  <div id="buslan-app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {

  },
  data(){
    return {

    }
  },
}
</script>

<style lang="scss">

@import "./scss/app.scss";

</style>
