<template>
  <b-list-group-item>
    <b-row v-if="edit">
      <b-col cols="8">
        <b-form-group label="Koodinaten">
          <b-input-group>
            <b-form-input
              v-model="latitude"
              placeholder="Breitengrad"
              trim
            ></b-form-input>

            <b-form-input
              v-model="longitude"
              placeholder="Längengrad"
              trim
            ></b-form-input>
            <template #append>
              <a
                :href="googleLink"
                target="_blank"
                class="btn btn-info"
                :disabled="!(latitude && longitude)"
                v-b-tooltip = "'Auf Google Maps anzeigen'"
              >
                <i class="fa fa-map" />
              </a>
            </template>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Radius">
          <b-input-group append="m">
            <b-form-input v-model.number="radius" trim></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <button class="btn btn-sm btn-success w-100" @click="speichern">Speichern</button>
        <button class="btn btn-sm btn-secondary w-100 mt-2" @click="cancel">Abbrechen</button>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="4">
        <label class="mr-2">Breitengrad:</label><span>{{ latitude }}</span>
      </b-col>
      <b-col cols="4">
        <label class="mr-2">Längengrad:</label><span>{{ longitude }}</span>
      </b-col>
      <b-col cols="1">
        <a :href="googleLink" target="_blank" class="btn btn-sm btn-info"><i class="fa fa-map"/></a>
      </b-col>
      <b-col cols="2">
        <label class="mr-2">Radius:</label><span>{{ radius }} m</span>
      </b-col>
      <b-col cols="1" class="text-right">
        <b-button-group size="sm">
          <button class="btn btn-success" @click="edit = true"><i class="fa fa-pencil"/></button>
          <button class="btn btn-danger" :id="'delete-popover-open-'+popoverId"><i class="fa fa-trash"/></button>
          <b-popover
            :target="'delete-popover-open-'+popoverId"
            triggers="focus"
            placement="auto"
            ref="deletePopover"
            title="Werbeposition löschen"
          >
            <div>Soll die Werbeposition wirklick gelöscht werden?</div>
            <button class="btn btn-sm btn-danger w-100 mt-3" @click="deletePosition()">Löschen</button>
          </b-popover>
        </b-button-group>
      </b-col>
    </b-row>
  </b-list-group-item>
</template>

<script>
  import _ from "lodash";
  import api from "../api";
  import { v4 as uuid } from "uuid";

  export default {
    name: "Werbeposition",
    props: {
      werbung: {
        type: String,
        required: true,
      },
      position: {
        type: Object,
        default: null,
      },
    },
    watch: {
      latitude(val) {
        if (val && _.split(val, ",").length === 2) {
          this.latitude = parseFloat(
            _.chain(val).split(",").first().trim().value()
          );
          this.longitude = parseFloat(
            _.chain(val).split(",").last().trim().value()
          );
        }
      },
      longitude(val) {
        if (val && _.split(val, ",").length === 2) {
          this.latitude = parseFloat(
            _.chain(val).split(",").first().trim().value()
          );
          this.longitude = parseFloat(
            _.chain(val).split(",").last().trim().value()
          );
        }
      },
    },
    data() {
      return {
        edit: false,
        latitude: null,
        longitude: null,
        radius: null,
        popoverShow: false
      };
    },
    created() {
      if (!this.position) this.edit = true;
      else {
        this.latitude = this.position.latitude;
        this.longitude = this.position.longitude;
        this.radius = this.position.radius;
      }
    },
    computed: {
      googleLink() {
        if (this.latitude && this.longitude)
          return `https://www.google.de/maps/@${this.latitude},${this.longitude},18.5z`;
        else return null;
      },
      popoverId() {
        return uuid();
      },
    },
    methods: {
      async speichern() {
        let json = {
          id: this.position?.id,
          latitude: parseFloat(this.latitude),
          longitude: parseFloat(this.longitude),
          radius: this.radius
        }

        if(this.position) {
          let response = await api.werbung.position.put(this.position.id, json);
          this.$emit('updated', response.data)
          this.edit = false;
        } else {
          let response = await api.werbung.position.post(this.werbung, json);
          this.$emit('updated', response.data)
        }
      },
      cancel() {
        if(this.position)
          this.edit = false
        else
          this.$emit("cancel")
      },
      async deletePosition() {
        if(this.position) {
          await api.werbung.position.delete(this.position.id);
          this.$emit("deleted")
        }
      }
    }
  };
</script>

<style>
</style>
