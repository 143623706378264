import api from "../config"

export default {
  getById: (id) => {
    return api.get("werbung/screen/", { params: { id }})
  },
  post: (werbung, screen) => {
    screen.werbung = werbung
    return api.post("werbung/screen/", screen)
  },
  put: (id, screen) => {
    return api.put("werbung/screen/", screen, { params: { id }})
  },
  delete: (id) => {
    return api.delete("werbung/screen/", { params: { id }})
  }
}
