import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store'
import vSelect from 'vue-select';

import BatixIndustrialUiFramework from 'batix-industrial-ui-framework';

Vue.use(BatixIndustrialUiFramework);

Vue.config.productionTip = false

Vue.component('v-select', vSelect);

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

import 'vue2-dropzone/dist/vue2Dropzone.min.css'

Vue.component('datetime-picker', VueCtkDateTimePicker);

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#buslan-app')
