<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-list-group>
          <b-list-group-item variant="primary">
            <b-row>
              <b-col cols="4"> Title </b-col>
              <b-col cols="6"> </b-col>
              <b-col cols="2"> </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item v-for="w in werbungen" :key="w.id">
            <b-row>
              <b-col cols="4">
                {{ w.titel }}
              </b-col>
              <b-col cols="6"> </b-col>
              <b-col cols="2" class="text-right">
                <b-button-group size="sm">
                  <router-link :to="{name: 'werbung.id', params: { id: w.id }}" class="btn btn-primary">
                    <i class="fa fa-pencil"/>
                  </router-link>
                </b-button-group>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col cols="12" class="mt-3">
        <b-pagination
          v-if="pagination"
          v-model="paginationPage"
          :total-rows="pagination.count"
          :per-page="20"
          aria-controls="my-table"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import api from "../api";

  export default {
    name: "WerbungListe",
    data() {
      return {
        werbungen: [],
        pagination: null,
        page: 0,
      };
    },
    watch: {
      async page() {
        await this.ladeWerbung();
      },
    },
    async created() {
      await this.ladeWerbung();
    },
    computed: {
      paginationPage: {
        get() {
          return this.page + 1;
        },
        set(val) {
          this.page = val - 1;
        },
      },
    },
    methods: {
      async ladeWerbung() {
        let response = await api.werbung.get(this.page, 20);
        this.werbungen = response.data.data;
        this.pagination = response.data.pagination;
      },
    },
  };
</script>

<style>
</style>
