import api from "../config"

export default {

  post: (werbung, position) => {
    position.werbung = werbung
    return api.post("werbung/position/", position)
  },
  put: (id, position) => {
    return api.put("werbung/position/", position, { params: { id }})
  },
  delete: (id) => {
    return api.delete("werbung/position/", { params: { id }})
  }
}
