<template>
  <b-container fluid="md">
    <b-row>
      <b-col cols="8">
        <bx-box>
          <bx-box variant="ice">
            <b-row>
              <b-col cols="6">
                <b-form-group label="Titel">
                  <b-form-input id="input-titel" v-model="titel" trim></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group label="Linie">
                  <b-form-input id="input-linie" v-model="linie" trim></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Anzeigezeit">
                  <b-input-group append="sek">
                    <b-form-input id="input-anzeigezeit" v-model.number="anzeigezeit" type="number" trim></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="">
                  <datetime-picker id="start" v-model="start" :color="datepickerColor" :button-color="datepickerColor" format="YYYY-MM-DD HH:mm" button-now-translation="Jetzt" label="Start-Zeit"/>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="">
                  <datetime-picker id="ende" v-model="ende" :color="datepickerColor" :button-color="datepickerColor" format="YYYY-MM-DD HH:mm"  button-now-translation="Jetzt" label="Ende-Zeit"/>
                </b-form-group>
              </b-col>
            </b-row>
          </bx-box>
        </bx-box>
      </b-col>
      <b-col cols="4">
        <bx-box>
          <bx-box variant="ice">
            <b-row>
              <b-col cols="10" offset="1" class="p-5">
                <button class="btn btn-sm btn-success w-100" @click="speichern">Speichern</button>
                <router-link :to="{ name: 'werbung.liste'}" class="btn btn-sm btn-secondary w-100 mt-2">Zurück</router-link>
              </b-col>
            </b-row>
          </bx-box>
        </bx-box>
      </b-col>
      <b-col v-if="id" cols="12" class="mt-3">
        <bx-box>
          <bx-box variant="ice">
            <h3>Anzeigepositionen</h3>
            <position v-for="(p,i) in positionen" :key="p.id" :position="p" :werbung="id" @updated="position => updatePosition(i, position)" @deleted="positionDeleted(i)"/>
            <position v-if="addPosition" :werbung="id" @cancel="addPosition = false" @updated="pushPosition"/>
            <b-row>
              <b-col cols="12 mt-2" class="text-center">
                <button class="btn btn-sm btn-primary" :disabled="addPosition" @click="addPosition = true">Werbeposition hinzufügen</button>
              </b-col>
            </b-row>
          </bx-box>
        </bx-box>
      </b-col>
      <b-col v-if="id" cols="12" class="mt-3">
        <bx-box>
          <bx-box variant="ice">
            <h3>Werbescreens</h3>
            <screen v-for="(s,i) in screens" :key="s.id" :screen="s" :werbung="id" @updated="screen => updateScreen(i, screen)" @deleted="screenDeleted(i)"/>
            <screen v-if="addScreen" :werbung="id" @cancel="addScreen = false" @updated="pushScreen"/>
            <b-row>
              <b-col cols="12 mt-2" class="text-center">
                <button class="btn btn-sm btn-primary" :disabled="addScreen" @click="addScreen = true">Werbescreen hinzufügen</button>
              </b-col>
            </b-row>
          </bx-box>
        </bx-box>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import api from "../api";
  import { DateTime } from 'luxon';
  import Position from "../components/WerbePosition";
  import Screen from "../components/WerbeScreen";


  export default {
    name: "Werbung",
    components: {
      Position,
      Screen
    },
    props: {
      id: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        titel: null,
        linie: null,
        hash: null,
        anzeigezeit: null,
        start: null,
        ende: null,
        screens: [],
        positionen: [],
        addPosition: false,
        addScreen: false,
        datepickerColor: '#354f68'
      };
    },
    async created() {
      if (this.id) {
        await this.ladeWerbung(this.id);
      }
    },
    methods: {
      async ladeWerbung(id) {
        let resposne = await api.werbung.getById(id);
        this.initializeWerbung(resposne.data);
      },
      async speichern() {
        let json = {
          id: this.id,
          titel: this.titel,
          linie: this.linie,
          hash: this.hash,
          anzeigezeit: this.anzeigezeit,
          start: this.start ? DateTime.fromFormat(this.start, "yyyy-MM-dd HH:mm").ts : null,
          ende: this.ende ? DateTime.fromFormat(this.ende, "yyyy-MM-dd HH:mm").ts : null
        }

        if(this.id) {
          let response = await api.werbung.put(this.id, json);
          this.initializeWerbung(response.data);
        } else {
          let response = await api.werbung.post(json);
          this.initializeWerbung(response.data);
          this.$router.replace({name: 'werbung.id', params: { id: response. id}});
        }
      },
      initializeWerbung(werbung) {
        this.titel = werbung.titel;
        this.linie = werbung.linie;
        this.anzeigezeit = werbung.anzeigezeit;
        this.hash = werbung.hash;
        this.ende = werbung.ende ? DateTime.fromMillis(werbung.ende).toFormat("yyyy-MM-dd HH:mm") : null;
        this.start = werbung.start ? DateTime.fromMillis(werbung.start).toFormat("yyyy-MM-dd HH:mm") : null;
        this.screens = werbung.screens;
        this.positionen = werbung.positionen;
      },
      pushPosition(position) {
        this.positionen.push(position);
        this.addPosition = false;
      },
      updatePosition(index, position) {
        this.positionen.splice(index, 1, position)
      },
      pushScreen(screen) {
        this.screens.push(screen);
        this.addScreen = false;
      },
      updateScreen(index, screen) {
        this.screens.splice(index, 1, screen)
      },
      screenDeleted(index) {
        this.screens.splice(index, 1)
      },
      positionDeleted(index) {
        this.positionen.splice(index, 1)
      }
    },
  };
</script>

<style>
</style>
